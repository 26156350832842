import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Select,
  FormErrorMessage,
  useToast,
  Text,
  Image,
  HStack,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Heading,
  Badge,
  Flex,
  useColorModeValue,
  SimpleGrid
} from '@chakra-ui/react';
import { FaUpload, FaTrash } from 'react-icons/fa';
import { SearchIcon, ChevronDownIcon, CheckIcon, WarningIcon } from '@chakra-ui/icons';
import axios from '@/utils/axios';
import { API_URLS } from '@/config/api';
import { useNavigate } from 'react-router-dom';

const Share = () => {
  const [formData, setFormData] = useState({
    nickname: '',
    uuid: '',
    link: '',
    xiaohongshu_type_id: '',
    reason: '',
    reason_images: [],
    verify_data: null
  });
  
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  
  const toast = useToast();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userInfo, setUserInfo] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [userInfoStatus, setUserInfoStatus] = useState({
    isConfirmed: false,
    isCorrect: null,
    confirmedTime: null
  });

  // 添加上榜类型状态
  const [blackTypes, setBlackTypes] = useState([]);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);

  // 获取上榜类型
  const fetchBlackTypes = async () => {
    setIsLoadingTypes(true);
    try {
      const response = await axios.get('/xiaohongshu/black/type');
      if (response.code === 1) {
        setBlackTypes(response.data);
      }
    } catch (error) {
      toast({
        title: "获取上榜类型失败",
        description: "请刷新页面重试",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingTypes(false);
    }
  };

  // 在组件加载时获取上榜类型
  useEffect(() => {
    fetchBlackTypes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name === 'black_type_id' ? 'xiaohongshu_type_id' : name]: value,
      ...(name === 'link' ? { verify_data: null } : {})
    }));

    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }

    if (name === 'link') {
      setUserInfoStatus({
        isConfirmed: false,
        isCorrect: null,
        confirmedTime: null
      });
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + images.length > 9) {
      toast({
        title: "上传失败",
        description: "最多只能上传9张图片",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    // 验证每个文件的大小（5MB限制）
    const maxSize = 5 * 1024 * 1024; // 5MB
    for (const file of files) {
      if (file.size > maxSize) {
        toast({
          title: "上传失败",
          description: `文件 ${file.name} 超过5MB限制`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    const newImages = files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setImages(prev => [...prev, ...newImages]);
  };

  const handleRemoveImage = (index) => {
    setImages(prev => {
      const newImages = [...prev];
      URL.revokeObjectURL(newImages[index].preview);
      newImages.splice(index, 1);
      return newImages;
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.link) newErrors.link = '请输入主页链接';
    if (!formData.xiaohongshu_type_id) newErrors.black_type_id = '请选择上榜类型';
    if (!formData.reason) newErrors.reason = '请输入上榜理由';
    if (!formData.verify_data) {
      newErrors.link = '请先验证用户信息';
    } else if (!formData.verify_data.isValid) {
      newErrors.link = '用户信息有误，请重新验证';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      // 修改图片上传部分
      let imageUrls = [];
      if (images.length > 0) {
        for (const image of images) {
          const formData = new FormData();
          formData.append('file', image.file); // 确保这里使用的是 image.file
          console.log(formData)
          // 添加请求头
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          };

          // 上传单张图片
          const uploadResponse = await axios.post('/xiaohongshu/common/upload', formData, config);
          if (uploadResponse.code === 1) {
            imageUrls.push(uploadResponse.data.path);
          }
        }
      }

      // 提交表单数据时确保包含图片URLs
      const submitData = {
        link: formData.link,
        xiaohongshu_type_id: formData.xiaohongshu_type_id,
        verify_data: formData.verify_data?.isValid,
        reason: formData.reason,
        reason_images: imageUrls
      };

      await axios.post('/xiaohongshu/black/add', submitData);

      toast({
        title: "提交成功",
        description: "感谢您的分享！",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });

      navigate('/');
    } catch (error) {
      toast({
        title: "提交失败",
        description: error.message || "请稍后重试",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // 修改查询用户信息的函数
  const handleSearch = async () => {
    if (!formData.link) {
      toast({
        title: "请输入主页链接",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    setIsSearching(true);
    try {
      const response = await axios.post('/xiaohongshu/xiaohongshu/get', {
        link: formData.link
      });
      
      if (response.code === 1) {
        const userData = response.data;
        setUserInfo({
          avatar: userData.avatar,
          nickname: userData.nick_name,
          uuid: userData.redbook_id,
          fans_count: userData.fans_num,
          desc: userData.Signature_field,
          gender: userData.gender,
          location: userData.ip_address,
          collectLike: userData.collect_like
        });
        onOpen();
      }
    } catch (error) {
    
    } finally {
      setIsSearching(false);
    }
  };

  // 信息正确处理函数
  const handleConfirmCorrect = () => {
    setFormData(prev => ({
      ...prev,
      nickname: userInfo.nickname,
      uuid: userInfo.uuid,
      verify_data: {
        ...userInfo,
        isValid: true,
        verifiedAt: new Date().toISOString()
      }
    }));

    setUserInfoStatus({
      isConfirmed: true,
      isCorrect: true,
      confirmedTime: new Date().toLocaleString()
    });

    toast({
      title: "已确认信息正确",
      status: "success",
      duration: 2000,
      isClosable: true,
    });

    onClose();
  };

  // 信息错误处理函数
  const handleConfirmIncorrect = () => {
    setFormData(prev => ({
      ...prev,
      verify_data: {
        ...userInfo,
        isValid: false,
        verifiedAt: new Date().toISOString()
      }
    }));

    setUserInfoStatus({
      isConfirmed: true,
      isCorrect: false,
      confirmedTime: new Date().toLocaleString()
    });

    toast({
      title: "已标记信息有误",
      status: "warning",
      duration: 2000,
      isClosable: true,
    });

    onClose();
  };

  return (
    <Box 
      maxW="1200px"
      w="100%"
      mx="auto"
      p={{ base: 4, md: 8, lg: 12 }}
      my={{ base: 4, md: 8 }}
      bg={useColorModeValue('white', 'gray.800')}
      borderRadius="2xl"
      boxShadow="xl"
      border="1px solid"
      borderColor={useColorModeValue('gray.100', 'gray.700')}
    >
      <VStack spacing={8} align="stretch">
        {/* 标题区域 */}
        <Box 
          textAlign="center" 
          pb={8}
          borderBottom="2px solid"
          borderColor={useColorModeValue('blue.100', 'blue.700')}
        >
          <Heading
            as="h1"
            size="xl"
            bgGradient="linear(to-r, blue.400, purple.500)"
            bgClip="text"
            letterSpacing="tight"
            mb={4}
          >
            添加达人上榜
          </Heading>
          <Text 
            color="gray.500"
            fontSize="lg"
          >
            分享踩雷经历，助你减少损失
          </Text>
        </Box>

        {/* 表单区域 */}
        <VStack spacing={8} align="stretch">
          {/* 主页链接输入框 */}
          <FormControl isRequired isInvalid={!!errors.link}>
            <FormLabel fontWeight="bold" fontSize="lg">
              主页链接
              {userInfoStatus.isConfirmed && (
                <HStack spacing={2} display="inline-flex" ml={2}>
                  <Badge 
                    colorScheme={formData.verify_data?.isValid ? "green" : "red"}
                    fontSize="sm"
                  >
                    {formData.verify_data?.isValid ? "信息正确" : "信息有误"}
                  </Badge>
                  <Badge 
                    colorScheme="gray"
                    fontSize="sm"
                  >
                    {userInfoStatus.confirmedTime}
                  </Badge>
                </HStack>
              )}
            </FormLabel>
            <InputGroup size="lg">
              <Input
                name="link"
                value={formData.link}
                onChange={handleInputChange}
                placeholder="请输入小红书主页链接"
                _focus={{
                  borderColor: "blue.400",
                  boxShadow: "0 0 0 1px blue.400"
                }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  colorScheme="blue"
                  onClick={handleSearch}
                  isLoading={isSearching}
                >
                  验证
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.link}</FormErrorMessage>
          </FormControl>

          {/* 修改上榜类型选择框 */}
          <FormControl isRequired isInvalid={!!errors.black_type_id}>
            <FormLabel fontWeight="bold" fontSize="lg">上榜类型</FormLabel>
            <Select
              name="black_type_id"
              value={formData.black_type_id}
              onChange={handleInputChange}
              placeholder="请选择上榜类型"
              size="lg"
              isDisabled={isLoadingTypes}
              icon={<ChevronDownIcon />}
              _focus={{
                borderColor: "blue.400",
                boxShadow: "0 0 0 1px blue.400"
              }}
            >
              {blackTypes.map(type => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
            </Select>
            {isLoadingTypes && (
              <Text fontSize="sm" color="gray.500" mt={1}>
                加载中...
              </Text>
            )}
            <FormErrorMessage>{errors.black_type_id}</FormErrorMessage>
          </FormControl>

          {/* 上榜理由输入框 */}
          <FormControl isRequired isInvalid={!!errors.reason}>
            <FormLabel fontWeight="bold">上榜理由</FormLabel>
            <Textarea
              name="reason"
              value={formData.reason}
              onChange={handleInputChange}
              placeholder="请详细描述达人的优点和特色..."
              minH="200px"
              size="lg"
              _focus={{
                borderColor: "blue.400",
                boxShadow: "0 0 0 1px blue.400"
              }}
            />
            <FormErrorMessage>{errors.reason}</FormErrorMessage>
          </FormControl>

          {/* 图片上传区域 */}
          <FormControl>
            <FormLabel fontWeight="bold" fontSize="lg">
              上传图片
              <Text as="span" fontSize="md" color="gray.500" ml={2}>
                （选填，最多9张）
              </Text>
            </FormLabel>
            <SimpleGrid 
              columns={{ base: 2, sm: 3, md: 4, lg: 5 }}
              spacing={6}
            >
              {images.map((image, index) => (
                <Box 
                  key={index} 
                  position="relative"
                  borderRadius="xl"
                  overflow="hidden"
                  boxShadow="md"
                  transition="transform 0.2s"
                  _hover={{ transform: 'scale(1.02)' }}
                >
                  <Image
                    src={image.preview}
                    alt={`预览图 ${index + 1}`}
                    w="full"
                    h="100px"
                    objectFit="cover"
                  />
                  <IconButton
                    icon={<FaTrash />}
                    size="sm"
                    position="absolute"
                    top={2}
                    right={2}
                    colorScheme="red"
                    variant="solid"
                    opacity={0.8}
                    _hover={{ opacity: 1 }}
                    onClick={() => handleRemoveImage(index)}
                  />
                </Box>
              ))}
              {images.length < 9 && (
                <Box
                  as="label"
                  htmlFor="image-upload"
                  w="full"
                  h="100px"
                  border="2px dashed"
                  borderColor="gray.200"
                  borderRadius="xl"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  transition="all 0.2s"
                  _hover={{
                    borderColor: "blue.400",
                    bg: "blue.50",
                    transform: 'scale(1.02)'
                  }}
                >
                  <FaUpload size="24px" color="gray.400" />
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    上传图片
                  </Text>
                  <input
                    id="image-upload"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                  />
                </Box>
              )}
            </SimpleGrid>
            <Text mt={2} fontSize="sm" color="gray.500">
              支持 jpg、png、gif 格式，单张图片不超过 5MB
            </Text>
          </FormControl>
        </VStack>

        {/* 提交按钮 */}
        <Button
          colorScheme="blue"
          size="lg"
          onClick={handleSubmit}
          isLoading={isLoading}
          w="full"
          mt={12}
          height="60px"
          fontSize="xl"
          bgGradient="linear(to-r, blue.400, purple.500)"
          _hover={{
            bgGradient: "linear(to-r, blue.500, purple.600)",
            transform: 'translateY(-2px)',
            boxShadow: 'xl'
          }}
          _active={{
            transform: 'translateY(0)',
            boxShadow: 'lg'
          }}
          transition="all 0.2s"
        >
          提交分享
        </Button>
      </VStack>

      {/* 用户信息确认弹窗 */}
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        size="2xl"
        isCentered
      >
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent borderRadius="2xl">
          <ModalHeader 
            fontSize="2xl"
            py={6}
            borderBottom="1px solid" 
            borderColor={useColorModeValue('gray.100', 'gray.700')}
          >
            确认用户信息
          </ModalHeader>
          <ModalCloseButton size="lg" />
          <ModalBody py={8}>
            {userInfo && (
              <VStack spacing={6} align="stretch">
                <Flex align="center" gap={4}>
                  <Image
                    src={userInfo.avatar}
                    alt="用户头像"
                    boxSize="120px"
                    borderRadius="full"
                    border="3px solid"
                    borderColor="blue.400"
                  />
                  <VStack align="start" flex={1} spacing={2}>
                    <Heading size="md">{userInfo.nickname}</Heading>
                    <Text color="gray.500">ID: {userInfo.uuid}</Text>
                    <HStack spacing={4}>
                      <Badge colorScheme="blue">
                        {userInfo.fans_count} 粉丝
                      </Badge>
                      <Badge colorScheme="pink">
                        {userInfo.collectLike} 获赞
                      </Badge>
                      <Badge colorScheme={userInfo.gender === '男' ? 'blue' : 'pink'}>
                        {userInfo.gender}
                      </Badge>
                      <Badge colorScheme="purple">
                        {userInfo.location}
                      </Badge>
                    </HStack>
                  </VStack>
                </Flex>
                <Box>
                  <Text fontWeight="bold" mb={2}>个人简介</Text>
                  <Text 
                    color="gray.600"
                    whiteSpace="pre-line"  // 保留换行符
                  >
                    {userInfo.desc || '暂无简介'}
                  </Text>
                </Box>
              </VStack>
            )}
          </ModalBody>
          <ModalFooter 
            py={6}
            borderTop="1px solid" 
            borderColor={useColorModeValue('gray.100', 'gray.700')}
          >
            <Button 
              colorScheme="red"
              size="lg"
              onClick={handleConfirmIncorrect}
              leftIcon={<WarningIcon />}
              mr={4}
            >
              信息有误
            </Button>
            <Button 
              colorScheme="green"
              size="lg"
              onClick={handleConfirmCorrect}
              leftIcon={<CheckIcon />}
            >
              信息正确
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Share; 