import React, { useState } from 'react';
import {
  Box,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  useMediaQuery,
  InputGroup,
  InputRightElement,
  Text,
  HStack,
  useToast,
  Heading,
  Container,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from '@/utils/axios';
import { API_URLS } from '@/config/api';

const Register = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [nickname, setNickname] = useState('');
  const [password, setPassword] = useState('');
  const [company, setCompany] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [errors, setErrors] = useState({
    nickname: '',
    password: '',
    mobile: '',
    code: ''
  });
  const [countdown, setCountdown] = useState(0);

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const handleSendVerificationCode = async () => {
    if (!phoneNumber) {
      setErrors(prev => ({ ...prev, mobile: '请输入手机号' }));
      return;
    }
    
    try {
      await axios.post(API_URLS.sendSms, {
        mobile: phoneNumber
      });
      
      setCountdown(60);
      const timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      
      toast({
        title: "验证码已发送",
        description: "请查看您的手机短信",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      // 错误处理已在 axios 拦截器中完成
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!nickname) newErrors.nickname = '昵称不能为空';
    if (!password) newErrors.password = '密码不能为空';
    if (!phoneNumber) newErrors.mobile = '手机号码不能为空';
    if (!verificationCode) newErrors.code = '验证码不能为空';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      await axios.post(API_URLS.register, {
        mobile: phoneNumber,
        code: verificationCode,
        nickname: nickname,
        password: password,
        company: company || undefined
      });
      
      toast({
        title: "注册成功",
        description: "欢迎加入我们！",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      navigate('/login');
    } catch (error) {
      // 将API错误信息显示在相应的输入框下
      if (error.message.includes('手机号')) {
        setErrors(prev => ({ ...prev, mobile: error.message }));
      } else if (error.message.includes('验证码')) {
        setErrors(prev => ({ ...prev, code: error.message }));
      } else if (error.message.includes('昵称')) {
        setErrors(prev => ({ ...prev, nickname: error.message }));
      } else if (error.message.includes('密码')) {
        setErrors(prev => ({ ...prev, password: error.message }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="lg" py={0}>
      <Box
        bg="white"
        p={8}
        borderRadius="lg"
        boxShadow="lg"
        w="full"
      >
        <VStack spacing={6} align="stretch">
          <Heading as="h2" size="xl" textAlign="center" mb={4}>
            注册账号
          </Heading>
          
          <FormControl isRequired isInvalid={!!errors.mobile}>
            <FormLabel>手机号</FormLabel>
            <InputGroup>
              <Input
                type="tel"
                placeholder="请输入手机号"
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setErrors(prev => ({ ...prev, mobile: '' }));
                }}
              />
              <InputRightElement width="auto" mr={1}>
                <Button 
                  size="sm" 
                  onClick={handleSendVerificationCode} 
                  colorScheme="blue" 
                  variant="outline"
                  isDisabled={countdown > 0}
                >
                  {countdown > 0 ? `${countdown}秒后重试` : '发送验证码'}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.mobile}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.code}>
            <FormLabel>验证码</FormLabel>
            <Input
              type="text"
              placeholder="请输入验证码"
              value={verificationCode}
              onChange={(e) => {
                setVerificationCode(e.target.value);
                setErrors(prev => ({ ...prev, code: '' }));
              }}
            />
            <FormErrorMessage>{errors.code}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.nickname}>
            <FormLabel>昵称</FormLabel>
            <Input
              type="text"
              placeholder="请输入昵称"
              value={nickname}
              onChange={(e) => {
                setNickname(e.target.value);
                setErrors(prev => ({ ...prev, nickname: '' }));
              }}
            />
            <FormErrorMessage>{errors.nickname}</FormErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={!!errors.password}>
            <FormLabel>密码</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                placeholder="请输入密码"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrors(prev => ({ ...prev, password: '' }));
                }}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleTogglePassword}>
                  {showPassword ? "隐藏" : "显示"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>公司名称（选填）</FormLabel>
            <Input
              type="text"
              placeholder="请输入公司名称"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </FormControl>

          <Button
            colorScheme="green"
            size="lg"
            width="100%"
            onClick={handleRegister}
            isLoading={isLoading}
            mt={4}
          >
            注册
          </Button>


          <HStack justifyContent="center" mt={4}>
            <Text>已有账号？</Text>
            <Button variant="link" colorScheme="blue" onClick={() => navigate('/login')}>
              立即登录
            </Button>
          </HStack>
        </VStack>
      </Box>
    </Container>
  );
};

export default Register;
