import React from 'react';
import { Box, Text, Link, useColorModeValue } from '@chakra-ui/react';
import useConfigStore from '@/store/configStore';

const Footer = () => {
  const textColor = useColorModeValue('gray.500', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const { config } = useConfigStore();

  return (
    <Box
      as="footer"
      borderTopWidth="1px"
      borderColor={borderColor}
      py={4}
      textAlign="center"
      mt="auto"
    >
      <Text fontSize="sm" color={textColor}>
        Copyright © {new Date().getFullYear()} All Rights Reserved.{' '}
        <Link 
          href="https://beian.miit.gov.cn/" 
          isExternal
          color={textColor}
          _hover={{ color: 'blue.500' }}
        >
          {config?.beian || '粤ICP备xxxxxxxx号'}
        </Link>
      </Text>
    </Box>
  );
};

export default Footer; 