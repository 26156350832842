import React from 'react';
import { Button, useColorModeValue, Flex, Box, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MenuItem = ({ icon, children, isActive, onClick, ...props }) => {
  const activeBg = useColorModeValue('blue.50', 'blue.900');
  const activeColor = useColorModeValue('blue.600', 'blue.200');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const iconColor = useColorModeValue('gray.500', 'gray.400');
  
  return (
    <Flex
      as={motion.div}
      whileHover={{ x: 4 }}
      whileTap={{ scale: 0.98 }}
      align="center"
      px={4}
      py={3}
      cursor="pointer"
      borderRadius="md"
      bg={isActive ? activeBg : 'transparent'}
      color={isActive ? activeColor : 'inherit'}
      role="group"
      transition="all 0.2s"
      onClick={onClick}
      {...props}
    >
      <Box 
        as={motion.div}
        color={isActive ? activeColor : iconColor}
        fontSize="1.2em" 
        mr={3}
        transition="all 0.2s"
        _groupHover={{
          transform: 'scale(1.1)',
        }}
      >
        {icon}
      </Box>
      <Text fontWeight={isActive ? "semibold" : "medium"}>{children}</Text>
    </Flex>
  );
};

export default MenuItem; 