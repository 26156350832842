import React from 'react';
import {
  Box,
  Container,
  Flex,
  Button,
  Spacer,
  useMediaQuery,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorModeValue,
  Text,
  Divider
} from '@chakra-ui/react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaShare, FaUser, FaBars, FaSignOutAlt } from 'react-icons/fa';
import useUserStore from '@/store/userStore';
import useConfigStore from '@/store/configStore';

const Header = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, setLoggedIn } = useUserStore();
  const { config } = useConfigStore();
  
  // 颜色主题
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const buttonHoverBg = useColorModeValue('gray.50', 'gray.700');
  const activeButtonBg = useColorModeValue('blue.50', 'blue.900');
  const activeButtonColor = useColorModeValue('blue.600', 'blue.200');

  const isActivePath = (path) => location.pathname === path;

  const handleLogoClick = () => navigate('/');
  
  const handleLogout = () => {
    localStorage.removeItem('token');
    setLoggedIn(false);
    navigate('/');
  };

  const NavButton = ({ to, icon, children, ...props }) => (
    <Button
      as={Link}
      to={to}
      leftIcon={icon}
      variant="ghost"
      size="sm"
      mx={1}
      px={4}
      bg={isActivePath(to) ? activeButtonBg : 'transparent'}
      color={isActivePath(to) ? activeButtonColor : 'inherit'}
      _hover={{
        bg: isActivePath(to) ? activeButtonBg : buttonHoverBg,
        transform: 'translateY(-1px)'
      }}
      transition="all 0.2s"
      {...props}
    >
      {children}
    </Button>
  );

  const getNavButtons = () => (
    <>
      <NavButton to="/" icon={<FaHome />}>首页</NavButton>
      <NavButton to="/share" icon={<FaShare />}>我要分享</NavButton>
      {isLoggedIn ? (
        <NavButton 
          to="/user-center" 
          icon={<FaUser />}
          colorScheme="blue"
          variant={isActivePath('/user-center') ? "solid" : "ghost"}
        >
          个人中心
        </NavButton>
      ) : (
        <NavButton to="/login" icon={<FaUser />}>登录</NavButton>
      )}
    </>
  );

  const MenuItem = ({ icon, children, ...props }) => (
    <Box
      as="button"
      w="full"
      px={4}
      py={3}
      display="flex"
      alignItems="center"
      _hover={{ bg: buttonHoverBg }}
      transition="all 0.2s"
      {...props}
    >
      <Box fontSize="1.1em" mr={3}>
        {icon}
      </Box>
      <Text>{children}</Text>
    </Box>
  );

  const getMenuItems = () => (
    <>
      <MenuItem as={Link} to="/" icon={<FaHome />}>首页</MenuItem>
      <MenuItem as={Link} to="/share" icon={<FaShare />}>我要分享</MenuItem>
      {isLoggedIn ? (
        <>
          <MenuItem as={Link} to="/user-center" icon={<FaUser />}>个人中心</MenuItem>
          <Divider my={2} />
          <MenuItem 
            onClick={handleLogout} 
            icon={<FaSignOutAlt />}
            color="red.500"
            _hover={{ bg: 'red.50' }}
          >
            退出登录
          </MenuItem>
        </>
      ) : (
        <MenuItem as={Link} to="/login" icon={<FaUser />}>登录</MenuItem>
      )}
    </>
  );

  return (
    <Box 
      as="header" 
      py={2} 
      bg={bgColor}
      borderBottom="1px"
      borderColor={borderColor}
      position="sticky"
      top={0}
      zIndex={1000}
      backdropFilter="blur(8px)"
      backgroundColor={useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(26, 32, 44, 0.8)')}
    >
      <Container maxW="container.xl">
        <Flex alignItems="center" height="48px">
          <Flex 
            alignItems="center" 
            cursor="pointer" 
            onClick={handleLogoClick}
            role="button"
            _hover={{ transform: 'scale(1.02)' }}
            transition="transform 0.2s"
          >
            {config?.website_logo && (
              <Box 
                as="img"
                src={config.website_logo}
                alt={config?.name || '小红书黑榜报'}
                height="32px"
                mr={2}
              />
            )}
            <Text
              fontWeight="bold"
              fontSize={isLargerThan768 ? "xl" : "lg"}
              bgGradient="linear(to-r, blue.500, blue.300)"
              bgClip="text"
            >
              {config?.name || '小红书黑榜报'}
            </Text>
          </Flex>
          <Spacer />
          <Flex align="center">
            {isLargerThan768 ? (
              getNavButtons()
            ) : (
              <Menu>
                <MenuButton as={IconButton} icon={<FaBars />} variant="outline" />
                <MenuList>
                  {getMenuItems()}
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;
