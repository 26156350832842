import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import useUserStore from '@/store/userStore';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const isLoggedIn = useUserStore(state => state.isLoggedIn);
  const hasShownToast = useRef(false);

  useEffect(() => {
    if (!isLoggedIn && !hasShownToast.current) {
      hasShownToast.current = true;
      toast({
        title: "需要登录",
        description: "请先登录后再访问该页面",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      navigate('/login');
    }
  }, [isLoggedIn, navigate, toast]);

  return isLoggedIn ? children : null;
};

export default ProtectedRoute; 