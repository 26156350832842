import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Container,
  Divider,
  Badge,
  Spinner,
  useColorModeValue,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  useToast,
  Alert,
  AlertIcon,
  Link,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import axios from '@/utils/axios';

const SearchResults = () => {
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const textColor = useColorModeValue('gray.800', 'white');

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const fetchSearchResults = async (query) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post('/xiaohongshu/black/search', {
        keyword: query
      });
      
      if (response.code === 1) {
        setSearchResults(response.data);
        // toast({
        //   title: "搜索成功",
        //   description: "本次搜索消耗1积分",
        //   status: "success",
        //   duration: 3000,
        //   isClosable: true,
        //   position: "top-right"
        // });
      } else {
        setError(response.msg || '搜索失败');
      }
    } catch (error) {
      console.error('搜索出错:', error);
      setError('搜索请求失败，请稍后重试');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchConfirm = () => {
    onClose();
    fetchSearchResults(searchQuery);
  };

  const handleSearch = () => {
    if (searchQuery.trim() === '') {
      toast({
        title: "搜索内容不能为空",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    fetchSearchResults(searchQuery);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const formatReason = (reason) => {
    return reason.split('\n').map((line, index) => (
      <Text key={index}>{line}</Text>
    ));
  };

  return (
    <Container maxW="container.xl" mt={{ base: 16, md: 20 }}>
      <Box width="100%" mb={8}>
        <InputGroup size="lg">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            ref={searchInputRef}
            type="text"
            placeholder="在小红书中搜索，或输入达人ID"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
            bg={bgColor}
            borderColor={borderColor}
            _hover={{ boxShadow: 'md' }}
            _focus={{ boxShadow: 'md', borderColor: 'blue.500' }}
            borderRadius="full"
            pr="4.5rem"
            autoComplete="off"
          />
          <InputRightElement width="4.5rem">
            <Button 
              h="1.75rem" 
              size="sm" 
              onClick={handleSearch}
              isLoading={isLoading}
            >
              搜索
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>搜索确认</ModalHeader>
          <ModalBody>
            本次搜索将消耗1积分，是否继续？
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              取消
            </Button>
            <Button colorScheme="blue" onClick={handleSearchConfirm}>
              确认搜索
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isLoading ? (
        <Box textAlign="center" mt={8}>
          <Spinner size="xl" />
        </Box>
      ) : error ? (
        <Alert status="error" mt={8}>
          <AlertIcon />
          {error}
        </Alert>
      ) : searchResults ? (
        searchResults.status === 'success' ? (
          <VStack spacing={8} align="stretch">
            <Heading size="lg" mb={6}>
              找到 <Text as="span" color="blue.500">{searchResults.total}</Text> 条记录
            </Heading>
            
            {searchResults.list.map((item, index) => (
              <Box 
                key={item.uuid}
                p={8}
                bg={bgColor}
                borderRadius="xl"
                boxShadow="lg"
                borderWidth="1px"
                _hover={{ transform: 'translateY(-2px)', boxShadow: 'xl' }}
                transition="all 0.2s"
              >
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <Box>
                    <Text fontSize="xl" fontWeight="bold" mb={2}>
                      {item.nickname}
                    </Text>
                    <Text color="gray.500" fontSize="md">
                      ID: {item.uuid}
                    </Text>
                    {item.link && (
                      <Link 
                        href={item.link} 
                        isExternal 
                        color="blue.500"
                        fontSize="md"
                        display="inline-flex"
                        alignItems="center"
                        mt={2}
                        _hover={{ textDecoration: 'none', color: 'blue.600' }}
                      >
                        查看主页 <ExternalLinkIcon ml={1} />
                      </Link>
                    )}
                  </Box>
                  <Box>
                    <Badge 
                      colorScheme={item.status === 1 ? 'green' : 'gray'}
                      px={3}
                      py={1}
                      borderRadius="full"
                      fontSize="sm"
                    >
                      {item.status === 1 ? '已通过' : '待审核'}
                    </Badge>
                    <Text fontSize="sm" color="gray.500" mt={3}>
                      提交时间: {new Date(item.createtime * 1000).toLocaleString()}
                    </Text>
                  </Box>
                </SimpleGrid>
                
                <Divider my={6} />
                
                <Box>
                  <Text fontSize="lg" fontWeight="medium" mb={3}>上榜理由:</Text>
                  <Box pl={4} color={textColor}>
                    {formatReason(item.reason)}
                  </Box>
                </Box>
              </Box>
            ))}
            
            {searchResults.total > searchResults.list.length && (
              <Text textAlign="center" color="gray.500">
                共 {searchResults.total} 条记录，当前显示第 {searchResults.current_page} 页
              </Text>
            )}
          </VStack>
        ) : (
          <Alert status="info" mt={8}>
            <AlertIcon />
            {searchResults.status_message || '没有找到相关结果'}
          </Alert>
        )
      ) : (
        <Box textAlign="center" mt={8} color="gray.500">
          请输入关键词开始搜索
        </Box>
      )}
    </Container>
  );
};

export default SearchResults;
