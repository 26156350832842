import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
  useColorModeValue,
  Container,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  useToast,
  Spinner,
  Alert,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  HStack,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { SearchIcon, InfoIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import axios from '@/utils/axios';
import useUserStore from '@/store/userStore';
import SearchResults from './Home/SearchResults';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Home = () => {
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const scoreCardBg = useColorModeValue('gray.50', 'gray.800');
  const highlightColor = useColorModeValue('blue.500', 'blue.300');

  const [userScore, setUserScore] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const navigate = useNavigate();
  const isLoggedIn = useUserStore(state => state.isLoggedIn);
  const toast = useToast();

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const searchInputRef = useRef(null);
  const suggestionsRef = useRef(null);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const cancelRef = useRef();

  useEffect(() => {
    const saved = localStorage.getItem('recentSearches');
    if (saved) {
      try {
        setRecentSearches(JSON.parse(saved));
      } catch (e) {
        console.error('Failed to parse recent searches:', e);
      }
    }
  }, []);

  useEffect(() => {
    const fetchUserScore = async () => {
      try {
        const response = await axios.get('/xiaohongshu/user/index');
        if (response.code === 1) {
          setUserScore(response.data.score);
        }
      } catch (error) {
        console.error('获取用户积分失败:', error);
      }
    };

    if (isLoggedIn) {
      fetchUserScore();
    }
  }, [isLoggedIn]);

  const handleSearchInput = (value) => {
    setSearchQuery(value);
    if (value.trim()) {
      const suggestions = recentSearches
        .filter(item => item.toLowerCase().includes(value.toLowerCase()))
        .slice(0, 5);
      setSearchSuggestions(suggestions);
      setShowSuggestions(true);
    } else {
      setSearchSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const addToRecentSearches = (query) => {
    const newSearches = [query, ...recentSearches.filter(item => item !== query)].slice(0, 5);
    setRecentSearches(newSearches);
    localStorage.setItem('recentSearches', JSON.stringify(newSearches));
  };

  const handleSearchClick = () => {
    if (!isLoggedIn) {
      toast({
        title: "请先登录",
        description: "登录后即可开始搜索达人",
        status: "info",
        duration: 2000,
        isClosable: true,
      });
      navigate('/login');
      return;
    }

    if (!searchQuery.trim()) {
      toast({
        title: "请输入搜索内容",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
      return;
    }

    setIsConfirmOpen(true);
  };

  const handleSearch = async () => {
    setIsConfirmOpen(false);
    setIsLoading(true);
    setError(null);
    setShowSuggestions(false);

    try {
      addToRecentSearches(searchQuery.trim());
      
      const response = await axios.post('/xiaohongshu/black/search', {
        keyword: searchQuery.trim()
      });
      
      if (response.code === 1) {
        setSearchResults(response.data);
        setIsSearchExpanded(true);
        const userResponse = await axios.get('/xiaohongshu/user/index');
        if (userResponse.code === 1) {
          setUserScore(userResponse.data.score);
        }
      } else {
        setError(response.msg || '搜索失败');
      }
    } catch (error) {
      setError('搜索请求失败，请稍后重试');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const animation = `${fadeIn} 0.6s ease-out`;

  const mainContentAnimation = {
    initial: { opacity: 1, y: 0 },
    expanded: { 
      opacity: isSearchExpanded ? 0 : 1,
      y: isSearchExpanded ? -20 : 0,
      transition: { duration: 0.3 }
    }
  };

  const searchResultsAnimation = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.3 }
  };

  return (
    <Container maxW="container.xl" centerContent position="relative">
      <motion.div
        initial="initial"
        animate="expanded"
        variants={mainContentAnimation}
        style={{ width: '100%' }}
      >
        <VStack spacing={[4, 6, 8]} align="center" w="100%" py={[8, 12, 16]}>
          <VStack spacing={[3, 4]}>
            <Heading 
              as="h1" 
              fontSize={["2xl", "3xl", "4xl"]}
              color={textColor} 
              textAlign="center"
              bgGradient={`linear(to-r, ${highlightColor}, blue.300)`}
              bgClip="text"
              letterSpacing="tight"
              fontWeight="extrabold"
              px={4}
            >
              真实踩雷故事-助你减少损失
            </Heading>
          </VStack>

          <Box w="100%" maxW="600px" px={[4, 6, 0]} position="relative">
            <InputGroup size={["md", "lg"]} mb={2}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                ref={searchInputRef}
                placeholder="输入小红书昵称、ID或小红书号"
                value={searchQuery}
                onChange={(e) => handleSearchInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSearchClick()}
                bg={bgColor}
                borderColor={borderColor}
                borderWidth="2px"
                borderRadius="full"
                _hover={{ borderColor: 'blue.400' }}
                _focus={{ borderColor: 'blue.500', boxShadow: 'outline' }}
                fontSize={["sm", "md"]}
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  icon={<SearchIcon />}
                  colorScheme={isLoggedIn ? "blue" : "gray"}
                  borderRadius="full"
                  onClick={handleSearchClick}
                  isLoading={isLoading}
                  aria-label="搜索"
                />
              </InputRightElement>
            </InputGroup>

            {showSuggestions && (searchSuggestions.length > 0 || recentSearches.length > 0) && (
              <Box
                ref={suggestionsRef}
                position="absolute"
                top="100%"
                left={0}
                right={0}
                bg={bgColor}
                boxShadow="lg"
                borderRadius="md"
                border="1px solid"
                borderColor={borderColor}
                zIndex={10}
                mt={2}
                overflow="hidden"
              >
                {searchSuggestions.length > 0 ? (
                  <VStack align="stretch" spacing={0}>
                    {searchSuggestions.map((suggestion, index) => (
                      <Box
                        key={index}
                        p={3}
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        onClick={() => {
                          setSearchQuery(suggestion);
                          setShowSuggestions(false);
                          handleSearchClick();
                        }}
                      >
                        <Text fontSize="sm">{suggestion}</Text>
                      </Box>
                    ))}
                  </VStack>
                ) : recentSearches.length > 0 && (
                  <VStack align="stretch" spacing={0}>
                    <Box p={2} bg="gray.50">
                      <Text fontSize="xs" color="gray.500">最近搜索</Text>
                    </Box>
                    {recentSearches.map((search, index) => (
                      <Box
                        key={index}
                        p={3}
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        onClick={() => {
                          setSearchQuery(search);
                          setShowSuggestions(false);
                          handleSearchClick();
                        }}
                      >
                        <Text fontSize="sm">{search}</Text>
                      </Box>
                    ))}
                  </VStack>
                )}
              </Box>
            )}

            <Box 
              bg={useColorModeValue('gray.50', 'gray.700')}
              p={[2, 3]}
              borderRadius="md"
              border="1px solid"
              borderColor={useColorModeValue('gray.200', 'gray.600')}
              fontSize={["xs", "sm"]}
              mt={4}
            >
              <VStack spacing={2} align="start">
                <HStack spacing={2}>
                  <InfoIcon color="blue.500" />
                  <Text fontWeight="medium" color={useColorModeValue('gray.700', 'gray.200')}>
                    支持以下搜索方式：
                  </Text>
                </HStack>
                <VStack spacing={1} align="start" pl={6}>
                  <Text color="gray.500">
                    • 小红书昵称：例如 "小红薯"
                  </Text>
                  <Text color="gray.500">
                    • 小红书号：例如 "xiaohongshu"
                  </Text>
                  <Text color="gray.500">
                    • 小红书ID：在个人主页链接的最后一串数字
                  </Text>
                  <Text color="gray.400">
                    示例链接：https://www.xiaohongshu.com/user/profile/<Text as="span" color="blue.500" fontWeight="bold">52d8c541b4c4d60e6c867480</Text>
                  </Text>
                </VStack>
              </VStack>
            </Box>
          </Box>

          <Text
            fontSize={["xs", "sm"]}
            color="gray.500"
            px={4}
            opacity={0.8}
            textAlign="center"
          >
            {isLoggedIn ? (
              <Text as="span">
                每次搜索消耗1积分 
                <Text as="span" color="blue.500" fontWeight="bold" ml={1}>
                  (当前积分: {userScore ?? 0})
                </Text>
              </Text>
            ) : '登录后即可开始搜索'}
          </Text>
        </VStack>
      </motion.div>

      <AnimatePresence mode="wait">
        {isSearchExpanded && (
          <motion.div
            {...searchResultsAnimation}
            style={{
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 2
            }}
          >
            <Box w="100%">
              {isLoading ? (
                <Box textAlign="center">
                  <Spinner size="xl" />
                </Box>
              ) : error ? (
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
              ) : searchResults && (
                <SearchResults 
                  results={searchResults} 
                  onBack={() => {
                    setIsSearchExpanded(false);
                    setSearchResults(null);
                    setSearchQuery('');
                  }}
                />
              )}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      <AlertDialog
        isOpen={isConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsConfirmOpen(false)}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={4}>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              确认搜索
            </AlertDialogHeader>

            <AlertDialogBody>
              <VStack align="start" spacing={3}>
                <Text>此次搜索将消耗1积分，当前积分：{userScore}</Text>
                <Text color="gray.500" fontSize="sm">
                  搜索关键词：{searchQuery}
                </Text>
              </VStack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>
                取消
              </Button>
              <Button 
                colorScheme="blue" 
                onClick={handleSearch} 
                ml={3}
                isDisabled={userScore < 1}
              >
                确认搜索
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};

export default Home;
