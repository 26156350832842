import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  SimpleGrid,
  useColorModeValue
} from '@chakra-ui/react';

const RecordDetails = () => {
  const [record, setRecord] = useState(null);
  const { id } = useParams();
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  useEffect(() => {
    // 这里应该发送API请求获取记录详情
    // 为了演示，我们使用模拟数据
    const mockRecord = {
      id: id,
      title: '上榜记录详情',
      date: '2023-05-01',
      content: '这是一段详细的上榜记录内容...',
      images: [
        'https://via.placeholder.com/300',
        'https://via.placeholder.com/300',
        'https://via.placeholder.com/300',
      ]
    };
    setRecord(mockRecord);
  }, [id]);

  if (!record) {
    return <Box>加载中...</Box>;
  }

  return (
    <Box p={4} bg={bgColor} color={textColor}>
      <VStack spacing={6} align="start">
        <Heading as="h2" size="xl">{record.title}</Heading>
        <Text>日期: {record.date}</Text>
        <Text>{record.content}</Text>
        <SimpleGrid columns={[1, 2, 3]} spacing={4}>
          {record.images.map((image, index) => (
            <Image key={index} src={image} alt={`图片 ${index + 1}`} />
          ))}
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default RecordDetails;
