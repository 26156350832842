import { create } from 'zustand';
import axios from '@/utils/axios';

const useConfigStore = create((set) => ({
  config: null,
  isLoading: false,
  error: null,
  
  // 获取配置
  fetchConfig: async () => {
    set({ isLoading: true });
    try {
      const response = await axios.get('/xiaohongshu/config/index');
      if (response.code === 1) {
        set({ config: response.data });
      }
    } catch (error) {
      set({ error: error.message });
    } finally {
      set({ isLoading: false });
    }
  },

  // 重置配置
  resetConfig: () => {
    set({
      config: null,
      error: null,
      isLoading: false
    });
  }
}));

export default useConfigStore; 