import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import { createStandaloneToast } from '@chakra-ui/react';

const { toast } = createStandaloneToast();

// 统一的错误提示函数
const showError = (message) => {
  toast({
    title: "错误提示",
    description: message,
    status: "error",
    duration: 3000,
    isClosable: true,
    position: "top",
  });
};

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  }
});

// 添加请求拦截器
instance.interceptors.request.use(
  config => {
    // 从 localStorage 获取 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['token'] = token;  // 添加 token 到请求头
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const res = response.data;
    
    if (res.code !== 1) {
      showError(res.msg || '请求失败');
      return Promise.reject(new Error(res.msg || '请求失败'));
    }
    
    return res;
  },
  error => {
    let message = '网络请求失败，请稍后重试';
    
    if (error.response) {
      switch (error.response.status) {
        case 400:
          message = '请求参数错误';
          break;
        // case 401:
        //   message = '未授权，请重新登录';
        //   // token 失效，清除本地存储并跳转到登录页
        //   localStorage.removeItem('token');
        //   window.location.href = '/login';
        //   break;
        case 403:
          message = '拒绝访问';
          break;
        case 404:
          message = '请求的资源不存在';
          break;
        case 500:
          message = '服务器内部错误';
          break;
        default:
          message = `请求失败: ${error.response.status}`;
      }
    } else if (error.request) {
      message = '服务器无响应';
    }
    
    showError(message);
    return Promise.reject(new Error(message));
  }
);

export default instance; 