import React, { useState, useEffect } from 'react';
import { 
  VStack, 
  Flex, 
  Avatar, 
  Text, 
  Divider, 
  Button,
  Box,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  Spinner,
  Center,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { FaCoins } from 'react-icons/fa';
import axios from '@/utils/axios';

const ProfileInfo = ({ isMobile }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [scoreRecords, setScoreRecords] = useState([]);
  const [searchRecords, setSearchRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const { isOpen: isPasswordModalOpen, onOpen: onPasswordModalOpen, onClose: onPasswordModalClose } = useDisclosure();
  const { isOpen: isCompanyModalOpen, onOpen: onCompanyModalOpen, onClose: onCompanyModalClose } = useDisclosure();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newCompanyName, setNewCompanyName] = useState('');
  const toast = useToast();

  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get('/xiaohongshu/user/index');
        if (response.code === 1) {
          setUserInfo(response.data);
        }
      } catch (error) {
        console.error('获取用户信息失败:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const [scoreResponse, searchResponse] = await Promise.all([
          axios.get('/xiaohongshu/score/lists'),
          axios.get('/xiaohongshu/search/lists')
        ]);
        
        if (scoreResponse.code === 1) {
          setScoreRecords(scoreResponse.data.list || []);
        }
        if (searchResponse.code === 1) {
          setSearchRecords(searchResponse.data.list || []);
        }
      } catch (error) {
        console.error('获取记录失败:', error);
      }
    };

    fetchRecords();
  }, []);

  const ScoreRecordsList = () => (
    <TableContainer>
      <Table variant="simple" size={isMobile ? "sm" : "md"}>
        <Thead>
          <Tr>
            <Th>时间</Th>
            <Th>类型</Th>
            <Th isNumeric>积分变动</Th>
            <Th>备注</Th>
          </Tr>
        </Thead>
        <Tbody>
          {scoreRecords.map((record) => (
            <Tr key={record.id}>
              <Td>{new Date(record.create_time * 1000).toLocaleString()}</Td>
              <Td>
                <Badge 
                  colorScheme={record.score > 0 ? "green" : "red"}
                >
                  {record.score > 0 ? "获得" : "消耗"}
                </Badge>
              </Td>
              <Td isNumeric color={record.score > 0 ? "green.500" : "red.500"}>
                {record.score > 0 ? `+${record.score}` : record.score}
              </Td>
              <Td>{record.remark}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  const SearchRecordsList = () => (
    <TableContainer>
      <Table variant="simple" size={isMobile ? "sm" : "md"}>
        <Thead>
          <Tr>
            <Th>时间</Th>
            <Th>搜索内容</Th>
            <Th>结果数</Th>
          </Tr>
        </Thead>
        <Tbody>
          {searchRecords.map((record) => (
            <Tr key={record.id}>
              <Td>{new Date(record.create_time * 1000).toLocaleString()}</Td>
              <Td>{record.keyword}</Td>
              <Td>{record.total || 0}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      toast({
        title: "密码不匹配",
        status: "error",
        duration: 3000,
      });
      return;
    }

    try {
      const response = await axios.post('/xiaohongshu/user/changePassword', {
        password: newPassword
      });
      if (response.code === 1) {
        toast({
          title: "密码修改成功",
          status: "success",
          duration: 3000,
        });
        onPasswordModalClose();
        setNewPassword('');
        setConfirmPassword('');
      }
    } catch (error) {
      toast({
        title: "密码修改失败",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleCompanyChange = async () => {
    try {
      const response = await axios.post('/xiaohongshu/user/changeCompany', {
        company: newCompanyName
      });
      if (response.code === 1) {
        toast({
          title: "公司名称修改成功",
          status: "success",
          duration: 3000,
        });
        setUserInfo(prev => ({...prev, company_name: newCompanyName}));
        onCompanyModalClose();
        setNewCompanyName('');
      }
    } catch (error) {
      toast({
        title: "公司名称修改失败",
        description: error.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  if (isLoading) {
    return (
      <Center py={8}>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (!userInfo) {
    return (
      <Center py={8}>
        <Text>获取用户信息失败</Text>
      </Center>
    );
  }

  return (
    <VStack align="stretch" spacing={6}>
      {/* 用户基本信息 */}
      <Flex align="center" gap={6} flexDirection={isMobile ? 'column' : 'row'}>
        <Avatar 
          size="2xl" 
          name={userInfo.nickname || 'User'} 
          src={userInfo.avatar}
          bg="blue.500" 
        />
        <VStack align={isMobile ? 'center' : 'start'} spacing={2} flex={1}>
          <Text fontWeight="bold" fontSize="3xl">{userInfo.nickname}</Text>
          <Text fontSize="sm" color="gray.500">{userInfo.email}</Text>
        </VStack>
      </Flex>

      {/* 积分信息 */}
      <Box 
        p={6} 
        // bg={bgColor} 
        borderRadius="lg" 
        borderWidth="1px"
        borderColor={borderColor}
        // shadow="md"
      >
        <Flex align="center" gap={4}>
          <Box color="yellow.500">
            <FaCoins size={24} />
          </Box>
          <Stat>
            <StatLabel fontSize="lg">当前积分</StatLabel>
            <StatNumber fontSize="3xl" color="yellow.500">
              {userInfo.score || 0}
            </StatNumber>
            {/* <StatHelpText>
              上次更新: {new Date(userInfo.score_update_time * 1000).toLocaleString()}
            </StatHelpText> */}
          </Stat>
        </Flex>
      </Box>

      <Divider />

      {/* 账户设置 */}
      <VStack spacing={4} align="stretch">
        <Text fontSize="xl" fontWeight="bold" mb={2}>账户设置</Text>
        
        <Flex justify="space-between" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <Text fontWeight="medium">公司名称:</Text>
          <Flex gap={2} align="center">
            <Text>{userInfo.company_name || '未设置'}</Text>
            <Button size="sm" colorScheme="blue" onClick={onCompanyModalOpen}>
              修改
            </Button>
          </Flex>
        </Flex>

        <Flex justify="space-between" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <Text fontWeight="medium">密码:</Text>
          <Button size="sm" colorScheme="blue" onClick={onPasswordModalOpen}>
            变更
          </Button>
        </Flex>
        

        <Flex justify="space-between" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <Text fontWeight="medium">注册时间:</Text>
          <Text>{new Date(userInfo.createtime * 1000).toLocaleString()}</Text>
        </Flex>
      </VStack>

      {/* 密码修改弹窗 */}
      <Modal isOpen={isPasswordModalOpen} onClose={onPasswordModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>修改密码</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>新密码</FormLabel>
                <Input 
                  type="password" 
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>确认密码</FormLabel>
                <Input 
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onPasswordModalClose}>
              取消
            </Button>
            <Button colorScheme="blue" onClick={handlePasswordChange}>
              确认
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* 公司名称修改弹窗 */}
      <Modal isOpen={isCompanyModalOpen} onClose={onCompanyModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>修改公司名称</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>公司名称</FormLabel>
              <Input 
                value={newCompanyName}
                onChange={(e) => setNewCompanyName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onCompanyModalClose}>
              取消
            </Button>
            <Button colorScheme="blue" onClick={handleCompanyChange}>
              确认
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default ProfileInfo; 