import React, { useState, useEffect } from 'react';
import { 
  ChakraProvider, 
  Box, 
  Container,
  Collapse,
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  Button
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import useUserStore from '@/store/userStore';
import useConfigStore from '@/store/configStore';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { theme } from './theme.ts';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import SearchResults from './components/SearchResults';
import RecordDetails from './components/RecordDetails';
import UserCenter from './pages/UserCenter';
import Share from './components/Share';
import { Global, css } from '@emotion/react';
import ProtectedRoute from './components/ProtectedRoute';

// 添加全局滚动条样式
const GlobalStyles = () => (
  <Global
    styles={css`
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background: #CBD5E0;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #A0AEC0;
      }
      * {
        scrollbar-width: thin;
        scrollbar-color: #CBD5E0 transparent;
      }
    `}
  />
);

// 创建一个新的内部组件
const AppContent = () => {
  const navigate = useNavigate();
  const [showPromo, setShowPromo] = useState(true);
  const isLoggedIn = useUserStore(state => state.isLoggedIn);
  const { config, fetchConfig } = useConfigStore();

  // 在组件加载时获取配置
  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const bgGradient = useColorModeValue(
    'linear(to-r, blue.500, blue.400)',
    'linear(to-r, blue.600, blue.500)'
  );
  const textColor = useColorModeValue('white', 'white');
  const buttonBgColor = useColorModeValue('whiteAlpha.200', 'whiteAlpha.200');
  const buttonHoverBgColor = useColorModeValue('whiteAlpha.300', 'whiteAlpha.300');

  return (
    <Box 
      minH="100vh" 
      bg="gray.50"
      display="flex"
      flexDirection="column"
    >
   
    
      <Collapse in={!isLoggedIn && showPromo}>
        <Box
          bgGradient={bgGradient}
          color={textColor}
          py={2}
          position="relative"
        >
          <Container maxW="container.xl">
            <Flex 
              justify="center" 
              align="center"
              position="relative"
              gap={4}
            >
              <Text 
                fontSize="sm"
                fontWeight="medium"
                textAlign="center"
              >
                🎁 新用户首次登录即可获得 
                <Text 
                  as="span" 
                  fontWeight="bold" 
                  fontSize="md" 
                  mx={1}
                >
                  {config?.register_points || 50}
                </Text> 
                积分，开启您的搜索之旅
              </Text>
              <Button
                size="sm"
                variant="ghost"
                color="white"
                bg={buttonBgColor}
                _hover={{ bg: buttonHoverBgColor }}
                onClick={() => navigate('/login')}
              >
                立即登录
              </Button>
              <IconButton
                icon={<CloseIcon />}
                size="xs"
                variant="ghost"
                position="absolute"
                right={0}
                color="white"
                _hover={{ bg: 'whiteAlpha.200' }}
                onClick={() => setShowPromo(false)}
                aria-label="关闭提示"
              />
            </Flex>
          </Container>
        </Box>
      </Collapse>
      <Header />
      <Box 
        flex="1"
        display="flex"
        flexDirection="column"
        maxH="calc(100vh - 64px)"
        overflowY="auto"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none'
        }}
      >
        <Container 
          maxW="container.xl" 
          py={8}
          flex="1"
          display="flex"
          flexDirection="column"
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/record/:id" element={<RecordDetails />} />
            <Route path="/user-center" element={
              <ProtectedRoute>
                <UserCenter />
              </ProtectedRoute>
            } />
            <Route path="/share" element={
              <ProtectedRoute>
                <Share />
              </ProtectedRoute>
            } />
          </Routes>
        </Container>
        <Footer />
      </Box>
    </Box>
  );
};

// 主 App 组件
function App() {
  return (
    <ChakraProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <AppContent />
      </Router>
    </ChakraProvider>
  );
}

export default App;
