import React, { useState } from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Badge, Spinner, Center, Image, VStack, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, useDisclosure, Text, SimpleGrid, Link } from '@chakra-ui/react';
import Pagination from './Pagination';

const ShareList = ({ isLoading, shareList, pagination, handlePageChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageClick = (images) => {
    setSelectedImages(images);
    onOpen();
  };

  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>达人昵称</Th>
              <Th>达人ID</Th>
              <Th>上榜类型</Th>
              <Th>上榜理由</Th>
              <Th>图片</Th>
              <Th>提交时间</Th>
              <Th>状态</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <Tr>
                <Td colSpan={7}>
                  <Center py={4}>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            ) : shareList.length === 0 ? (
              <Tr>
                <Td colSpan={7}>
                  <Center py={4}>暂无数据</Center>
                </Td>
              </Tr>
            ) : (
              shareList.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    {item.author ? (
                      <Link href={item.link} isExternal color="blue.500">
                        {item.author.nickname}
                      </Link>
                    ) : '未知'}
                  </Td>
                  <Td>{item.uuid}</Td>
                  <Td>{item.type?.name}</Td>
                  <Td maxW="300px" isTruncated title={item.reason}>
                    {item.reason}
                  </Td>
                  <Td>
                    {item.reason_images_urls.length > 0 && (
                      <Box position="relative" display="inline-block" cursor="pointer" onClick={() => handleImageClick(item.reason_images_urls)}>
                        <Image
                          src={item.reason_images_urls[0]}
                          alt="图片1"
                          boxSize="50px"
                          objectFit="cover"
                          borderRadius="md"
                          transition="transform 0.2s"
                          _hover={{ transform: 'scale(1.1)' }}
                        />
                        {item.reason_images_urls.length > 1 && (
                          <Text position="absolute" top="0" right="0" bg="rgba(0, 0, 0, 0.6)" color="white" fontSize="xs" borderRadius="md" px={1}>
                            +{item.reason_images_urls.length - 1}
                          </Text>
                        )}
                      </Box>
                    )}
                  </Td>
                  <Td>{new Date(item.createtime * 1000).toLocaleString()}</Td>
                  <Td>
                    <Badge colorScheme={item.status === 1 ? 'green' : 'gray'}>
                      {item.status_text}
                    </Badge>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      
      {!isLoading && shareList.length > 0 && (
        <Pagination
          currentPage={pagination.current_page}
          totalPages={pagination.last_page}
          onPageChange={handlePageChange}
        />
      )}

      {/* 图片查看模态框 */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={4}>
              {selectedImages.map((url, index) => (
                <Image key={index} src={url} alt={`查看图片${index + 1}`} w="full" h="auto" borderRadius="md" />
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ShareList; 