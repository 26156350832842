import React, { useState } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Image,
  Badge,
  Button,
  Heading,
  Divider,
  useColorModeValue,
  Icon,
  SimpleGrid,
  Alert,
  AlertIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { ArrowBackIcon, WarningIcon } from '@chakra-ui/icons';
import { FaUser, FaHeart, FaMapMarkerAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import { zhCN } from 'date-fns/locale';

const SearchResults = ({ results, onBack }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const recordBgColor = useColorModeValue('red.50', 'red.900');
  const recordBorderColor = useColorModeValue('red.100', 'red.700');

  // 添加图片查看相关状态
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImageClick = (imageUrl) => {
    setSelectedImages([imageUrl]); // 只显示点击的那张图片
    onOpen();
  };

  return (
    <Box 
      bg={bgColor} 
      borderRadius={["lg", "xl"]}
      boxShadow="xl"
      p={[3, 4, 6]}
      border="1px solid"
      borderColor={borderColor}
    >
      <VStack spacing={[4, 6]} align="stretch">
        {/* 头部返回区域 */}
        <HStack justify="space-between" align="center">
          <Button
            leftIcon={<ArrowBackIcon />}
            variant="ghost"
            onClick={onBack}
            size={["xs", "sm"]}
            fontSize={["sm", "md"]}
          >
            返回搜索
          </Button>
          <Text color="gray.500" fontSize={["xs", "sm"]}>
            找到 {results.total} 条记录
          </Text>
        </HStack>

        <Divider />

        {/* 根据状态显示不同内容 */}
        {results.status !== 'success' ? (
          <Alert 
            status="info"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            borderRadius="lg"
            py={[4, 6]}
          >
            <AlertIcon boxSize={["32px", "40px"]} mr={0} mb={4} />
            <Text fontSize={["md", "lg"]} fontWeight="medium">
              {results.status_message}
            </Text>
            <Text mt={2} color="gray.600" fontSize={["sm", "md"]}>
              请尝试使用其他关键词搜索
            </Text>
          </Alert>
        ) : (
          /* 搜索结果列表 */
          results.list.map((user) => (
            <Box 
              key={user.id}
              p={[4, 5, 6]}
              borderRadius="lg"
              border="1px solid"
              borderColor={borderColor}
              _hover={{ borderColor: 'blue.400', transform: 'translateY(-2px)' }}
              transition="all 0.2s"
            >
              {/* 用户基本信息 */}
              <VStack spacing={[4, 6]} align="stretch">
                <HStack spacing={[3, 4, 6]} align="start">
                  <Image
                    src={user.avatar}
                    alt={user.nickname}
                    boxSize={["60px", "80px", "100px"]}
                    borderRadius="full"
                    border="3px solid"
                    borderColor="blue.400"
                    objectFit="cover"
                  />
                  
                  <VStack align="start" flex={1} spacing={[2, 3]}>
                    <HStack wrap="wrap" spacing={2}>
                      <Heading size={["sm", "md"]}>{user.nickname}</Heading>
                      <Badge colorScheme={user.gender === '男' ? 'blue' : 'pink'}>
                        {user.gender}
                      </Badge>
                    </HStack>

                    <VStack spacing={2} align="start">
                      <HStack spacing={4} color="gray.600" fontSize={["xs", "sm"]}>
                        <HStack>
                          <Icon as={FaUser} />
                          <Text>{user.redbook_id}</Text>
                        </HStack>
                        <HStack>
                          <Icon as={FaHeart} />
                          <Text>{user.fans_num} 粉丝</Text>
                        </HStack>
                      </HStack>
                      <HStack color="gray.600" fontSize={["xs", "sm"]}>
                        <Icon as={FaMapMarkerAlt} />
                        <Text>{user.ip_address}</Text>
                      </HStack>
                    </VStack>

                    {user.signature && (
                      <Text color="gray.600" fontSize={["xs", "sm"]} noOfLines={2}>
                        {user.signature}
                      </Text>
                    )}
                  </VStack>
                </HStack>

                {/* 上榜记录 */}
                {user.black && user.black.length > 0 && (
                  <Box>
                    <Heading size={["xs", "sm"]} mb={[3, 4]}>
                      上榜记录 ({user.black.length})
                    </Heading>
                    <SimpleGrid columns={1} spacing={[3, 4]}>
                      {user.black.map((record) => (
                        <Box
                          key={record.id}
                          p={[3, 4]}
                          bg={recordBgColor}
                          borderRadius="md"
                          border="1px solid"
                          borderColor={recordBorderColor}
                        >
                          <VStack align="stretch" spacing={2}>
                            <HStack spacing={2}>
                              <WarningIcon color="red.500" />
                              <Text fontWeight="bold" fontSize={["xs", "sm"]}>
                                上榜时间：{format(record.createtime * 1000, 'yyyy年MM月dd日', { locale: zhCN })}
                              </Text>
                            </HStack>
                            <Text fontSize={["xs", "sm"]} color="gray.600">
                              {record.reason}
                            </Text>
                            {/* 图片展示部分 */}
                            {record.reason_images_urls && record.reason_images_urls.length > 0 && (
                              <Wrap spacing={2}>
                                {record.reason_images_urls.map((url, index) => (
                                  <WrapItem key={index}>
                                    <Box
                                      position="relative"
                                      cursor="pointer"
                                      onClick={() => handleImageClick(url)}
                                      transition="transform 0.2s"
                                      _hover={{ transform: 'scale(1.05)' }}
                                    >
                                      <Image
                                        src={url}
                                        alt={`证据图片${index + 1}`}
                                        boxSize={["60px", "70px", "80px"]}
                                        objectFit="cover"
                                        borderRadius="md"
                                      />
                                    </Box>
                                  </WrapItem>
                                ))}
                              </Wrap>
                            )}
                          </VStack>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Box>
                )}
              </VStack>
            </Box>
          ))
        )}
      </VStack>

      {/* 图片查看模态框 */}
      <Modal 
        isOpen={isOpen} 
        onClose={onClose} 
        isCentered 
        size={["full", "xl", "2xl"]}
      >
        <ModalOverlay backdropFilter="blur(5px)" />
        <ModalContent margin={[2, 4]}>
          <ModalCloseButton zIndex="popover" />
          <ModalBody p={[2, 4]}>
            <Image
              src={selectedImages[0]}
              alt="查看大图"
              w="full"
              h="auto"
              maxH="80vh"
              objectFit="contain"
              borderRadius="md"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SearchResults;