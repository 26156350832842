import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  Button,
  FormControl,
  FormLabel,
  Input,
  Tabs,
  TabList,
  Tab,
  InputGroup,
  InputRightElement,
  useMediaQuery,
  Text,
  HStack,
  useToast,
  FormErrorMessage,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import axios from '@/utils/axios';
import { API_URLS } from '@/config/api';
import useUserStore from '@/store/userStore';

const Login = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [loginMethod, setLoginMethod] = useState('sms');
  const [countdown, setCountdown] = useState(0);
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    mobile: '',
    code: '',
    password: ''
  });
  
  const navigate = useNavigate();
  const toast = useToast();
  const setLoggedIn = useUserStore(state => state.setLoggedIn);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleSendCode = async () => {
    if (!mobile) {
      setErrors(prev => ({ ...prev, mobile: '请输入手机号' }));
      return;
    }

    try {
      await axios.post(API_URLS.sendSms, {
        mobile: mobile
      });
      
      setCountdown(60);
      toast({
        title: "验证码已发送",
        description: "请查看您的手机短信",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      // 错误处理已在 axios 拦截器中完成
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!mobile) {
      newErrors.mobile = '请输入手机号';
    }
    
    if (loginMethod === 'sms' && !code) {
      newErrors.code = '请输入验证码';
    }
    
    if (loginMethod === 'password' && !password) {
      newErrors.password = '请输入密码';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async () => {
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const params = {
        mobile
      };

      // 根据登录方式选择不同的接口
      const url = loginMethod === 'sms' ? API_URLS.loginSms : API_URLS.loginPassword;
      
      // 根据登录方式添加不同的参数
      if (loginMethod === 'sms') {
        params.code = code;
      } else {
        params.password = password;
      }

      const response = await axios.post(url, params);
      
      // 登录成功处理
      toast({
        title: "登录成功",
        description: "欢迎回来！",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });

      // 存储token
      if (response.data?.token) {
        localStorage.setItem('token', response.data.token);
        setLoggedIn(true);
      }

      // 登录成功后跳转
      navigate('/');
    } catch (error) {
      // 错误处理已在 axios 拦截器中完成
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <Box 
      maxWidth="800px"  // 增加最大宽度
      w="100%"
      margin="auto" 
      p={{ base: 6, md: 12 }}  // 增加内边距
      boxShadow="xl" 
      borderRadius="2xl" 
      bg={useColorModeValue('white', 'gray.800')}
      border="1px solid"
      borderColor={useColorModeValue('gray.100', 'gray.700')}
    >
      <VStack spacing={8}>
        {/* 标题区域 */}
        <VStack spacing={3}>
          <Heading
            size="xl"
            bgGradient="linear(to-r, blue.400, purple.500)"
            bgClip="text"
          >
            欢迎回来
          </Heading>
          <Text color="gray.500" fontSize="lg">
            登录您的账号以继续操作
          </Text>
        </VStack>

        {/* 登录表单 */}
        <Box w="100%" maxW="600px">  {/* 控制表单区域最大宽度 */}
          <VStack spacing={8} align="stretch">
            <Tabs 
              isFitted 
              variant="soft-rounded" 
              colorScheme="blue" 
              width="100%"
              size="lg"
            >
              <TabList mb={6}>
                <Tab 
                  onClick={() => setLoginMethod('sms')}
                  _selected={{ 
                    color: 'white',
                    bg: 'blue.500'
                  }}
                  fontSize="lg"
                >
                  验证码登录
                </Tab>
                <Tab 
                  onClick={() => setLoginMethod('password')}
                  _selected={{ 
                    color: 'white',
                    bg: 'blue.500'
                  }}
                  fontSize="lg"
                >
                  密码登录
                </Tab>
              </TabList>
            </Tabs>
            
            {loginMethod === 'sms' ? (
              <>
                <FormControl isInvalid={!!errors.mobile}>
                  <FormLabel fontSize="lg">手机号</FormLabel>
                  <InputGroup size="lg">
                    <Input 
                      type="tel" 
                      placeholder="请输入手机号"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                        setErrors(prev => ({ ...prev, mobile: '' }));
                      }}
                      _focus={{
                        borderColor: "blue.400",
                        boxShadow: "0 0 0 1px blue.400"
                      }}
                    />
                    <InputRightElement width="auto" mr={1}>
                      <Button 
                        colorScheme="blue" 
                        size="sm"
                        px={4}
                        onClick={handleSendCode}
                        isDisabled={countdown > 0}
                      >
                        {countdown > 0 ? `${countdown}秒后重发` : '发送验证码'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.mobile}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.code}>
                  <FormLabel fontSize="lg">验证码</FormLabel>
                  <Input 
                    type="text" 
                    placeholder="请输入验证码"
                    value={code}
                    size="lg"
                    onChange={(e) => {
                      setCode(e.target.value);
                      setErrors(prev => ({ ...prev, code: '' }));
                    }}
                    _focus={{
                      borderColor: "blue.400",
                      boxShadow: "0 0 0 1px blue.400"
                    }}
                  />
                  <FormErrorMessage>{errors.code}</FormErrorMessage>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl isInvalid={!!errors.mobile}>
                  <FormLabel fontSize="lg">手机号</FormLabel>
                  <Input 
                    type="tel" 
                    placeholder="请输入手机号"
                    value={mobile}
                    size="lg"
                    onChange={(e) => {
                      setMobile(e.target.value);
                      setErrors(prev => ({ ...prev, mobile: '' }));
                    }}
                    _focus={{
                      borderColor: "blue.400",
                      boxShadow: "0 0 0 1px blue.400"
                    }}
                  />
                  <FormErrorMessage>{errors.mobile}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.password}>
                  <FormLabel fontSize="lg">密码</FormLabel>
                  <Input 
                    type="password" 
                    placeholder="请输入密码"
                    value={password}
                    size="lg"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors(prev => ({ ...prev, password: '' }));
                    }}
                    _focus={{
                      borderColor: "blue.400",
                      boxShadow: "0 0 0 1px blue.400"
                    }}
                  />
                  <FormErrorMessage>{errors.password}</FormErrorMessage>
                </FormControl>
              </>
            )}

            <Button 
              colorScheme="blue" 
              width="100%" 
              size="lg"
              height="56px"
              fontSize="lg"
              onClick={handleLogin}
              isLoading={isLoading}
              bgGradient="linear(to-r, blue.400, purple.500)"
              _hover={{
                bgGradient: "linear(to-r, blue.500, purple.600)",
                transform: 'translateY(-2px)',
                boxShadow: 'xl'
              }}
              _active={{
                transform: 'translateY(0)',
                boxShadow: 'lg'
              }}
              transition="all 0.2s"
            >
              登录
            </Button>
            
            <HStack width="100%" justifyContent="center" pt={4}>
              
              <Button variant="link" colorScheme="blue" onClick={handleRegister}>
                立即注册
              </Button>
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};

export default Login;
