// 根据环境设置 API 地址
export const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? '' // 生产环境使用空字符串，会自动使用当前域名
  : 'http://106.15.64.181:8000'; // 开发环境使用完整地址

export const API_URLS = {
  register:API_BASE_URL+'/xiaohongshu/login/reg',
  sendSms: API_BASE_URL + '/xiaohongshu/sms/send',
  loginSms: API_BASE_URL + '/xiaohongshu/login/sms',
  loginPassword: API_BASE_URL + '/xiaohongshu/login/password',
  uploadImages: '/xiaohongshu/upload/images',
  submitShare: '/xiaohongshu/share/submit'
}; 
