import React from 'react';
import { Flex, Button } from '@chakra-ui/react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <Flex justify="center" mt={4} gap={2}>
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        isDisabled={currentPage === 1}
        size="sm"
      >
        上一页
      </Button>
      
      {Array.from({ length: totalPages }).map((_, i) => (
        <Button
          key={i + 1}
          onClick={() => onPageChange(i + 1)}
          colorScheme={currentPage === i + 1 ? "blue" : "gray"}
          size="sm"
        >
          {i + 1}
        </Button>
      ))}
      
      <Button
        onClick={() => onPageChange(currentPage + 1)}
        isDisabled={currentPage >= totalPages}
        size="sm"
      >
        下一页
      </Button>
    </Flex>
  );
};

export default Pagination; 