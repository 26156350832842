import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Spinner,
  Center,
  Text,
  Box,
  Flex,
  Button,
  ButtonGroup
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import axios from '@/utils/axios';

const SearchRecords = () => {
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    lastPage: 1,
    total: 0
  });

  const fetchRecords = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.get('/xiaohongshu/search/lists', {
        params: { page }
      });
      
      if (response.code === 1) {
        setRecords(response.data.data || []);
        setPagination({
          currentPage: response.data.current_page,
          lastPage: response.data.last_page,
          total: response.data.total
        });
      } else {
        setError(response.msg || '获取记录失败');
      }
    } catch (error) {
      setError('获取记录失败');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRecords(1);
  }, []);

  const handlePageChange = (page) => {
    fetchRecords(page);
  };

  if (isLoading) {
    return (
      <Center py={8}>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center py={8}>
        <Text color="red.500">{error}</Text>
      </Center>
    );
  }

  return (
    <Box>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>时间</Th>
              <Th>搜索内容</Th>
              <Th>ID</Th>
            </Tr>
          </Thead>
          <Tbody>
            {records.map((record) => (
              <Tr key={record.id}>
                <Td>{new Date(record.createtime * 1000).toLocaleString()}</Td>
                <Td maxW="300px" isTruncated>{record.keyword}</Td>
                <Td>{record.id}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* 分页控件 */}
      <Flex justify="space-between" align="center" mt={6}>
        <Text color="gray.600">
          共 {pagination.total} 条记录
        </Text>
        <ButtonGroup spacing={2} variant="outline">
          <Button
            leftIcon={<ChevronLeftIcon />}
            onClick={() => handlePageChange(pagination.currentPage - 1)}
            isDisabled={pagination.currentPage === 1}
          >
            上一页
          </Button>
          <Button
            rightIcon={<ChevronRightIcon />}
            onClick={() => handlePageChange(pagination.currentPage + 1)}
            isDisabled={pagination.currentPage === pagination.lastPage}
          >
            下一页
          </Button>
        </ButtonGroup>
        <Text color="gray.600">
          第 {pagination.currentPage} / {pagination.lastPage} 页
        </Text>
      </Flex>
    </Box>
  );
};

export default SearchRecords; 