import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  Flex,
  VStack,
  Text,
  Divider,
  useColorModeValue,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaUser, FaHistory, FaShare, FaCoins, FaSignOutAlt, FaBars } from 'react-icons/fa';
import axios from '@/utils/axios';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import useUserStore from '@/store/userStore';
import { motion } from 'framer-motion';

import MenuItem from '../components/UserCenter/MenuItem';
import ProfileInfo from '../components/UserCenter/ProfileInfo';
import ShareList from '../components/UserCenter/ShareList';
import ScoreRecords from '../components/UserCenter/ScoreRecords';
import SearchRecords from '../components/UserCenter/SearchRecords';

const UserCenter = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'white');
  const pageBgColor = useColorModeValue('gray.50', 'gray.900');
  const headerBgColor = useColorModeValue(
    'rgba(255, 255, 255, 0.8)',
    'rgba(26, 32, 44, 0.8)'
  );
  const drawerBgColor = useColorModeValue('white', 'gray.800');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false }, {
    fallback: 'md'
  });
  const [activeTab, setActiveTab] = useState('个人信息');
  const [shareList, setShareList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  });
  const navigate = useNavigate();
  const toast = useToast();
  const { setLoggedIn } = useUserStore();

  const fetchShareList = useCallback(async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.get('/xiaohongshu/black/lists', {
        params: {
          page,
          page_size: pagination.pageSize
        }
      });
      
      if (response.code === 1) {
        setShareList(response.data.data || []);
        setPagination({
          currentPage: response.data.current_page,
          pageSize: 10,
          total: response.data.total
        });
      }
    } catch (error) {
      console.error('获取分享列表失败:', error);
    } finally {
      setIsLoading(false);
    }
  }, [pagination.pageSize]);

  useEffect(() => {
    if (activeTab === '分享记录') {
      fetchShareList(1);
    }
  }, [activeTab, fetchShareList]);

  const handlePageChange = (page) => {
    fetchShareList(page);
  };

  const renderContent = () => {
    switch (activeTab) {
      case '个人信息':
        return <ProfileInfo isMobile={isMobile} />;
      case '搜索记录':
        return <SearchRecords />;
      case '分享记录':
        return (
          <ShareList
            isLoading={isLoading}
            shareList={shareList}
            pagination={pagination}
            handlePageChange={handlePageChange}
          />
        );
      case '积分记录':
        return <ScoreRecords />;
      default:
        return null;
    }
  };

  const handleLogout = () => {
    // 清除token
    localStorage.removeItem('token');
    // 更新登录状态
    setLoggedIn(false);
    
    toast({
      title: "已退出登录",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    
    // 跳转到登录页
    navigate('/login');
  };

  const menuItems = (
    <VStack align="stretch" w="full" spacing={1}>
      <MenuItem 
        icon={<FaUser />} 
        isActive={activeTab === '个人信息'} 
        onClick={() => { 
          setActiveTab('个人信息'); 
          if (isMobile) onClose(); 
        }}
      >
        个人信息
      </MenuItem>
      <MenuItem 
        icon={<FaHistory />} 
        isActive={activeTab === '搜索记录'} 
        onClick={() => { 
          setActiveTab('搜索记录'); 
          if (isMobile) onClose(); 
        }}
      >
        搜索记录
      </MenuItem>
      <MenuItem 
        icon={<FaShare />} 
        isActive={activeTab === '分享记录'} 
        onClick={() => { 
          setActiveTab('分享记录'); 
          if (isMobile) onClose(); 
        }}
      >
        分享记录
      </MenuItem>
      <MenuItem 
        icon={<FaCoins />} 
        isActive={activeTab === '积分记录'} 
        onClick={() => { 
          setActiveTab('积分记录'); 
          if (isMobile) onClose(); 
        }}
      >
        积分记录
      </MenuItem>
      <Divider my={3} />
      <MenuItem 
        icon={<FaSignOutAlt />} 
        onClick={handleLogout}
        _hover={{ 
          color: 'red.500',
          bg: 'red.50',
        }}
        color="gray.600"
      >
        退出登录
      </MenuItem>
    </VStack>
  );

  const pageTransition = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
    transition: { duration: 0.2 }
  };

  return (
    <Box 
      minH="calc(100vh - 64px)"
      bg={pageBgColor}
    >
      <Container 
        maxW="container.xl" 
        py={isMobile ? 2 : 6}
        px={isMobile ? 0 : 4}
      >
        {isMobile && (
          <Box 
            position="sticky"
            top={0}
            zIndex={10}
            bg={bgColor}
            borderBottomWidth={1}
            borderColor={borderColor}
            backdropFilter="blur(8px)"
            backgroundColor={headerBgColor}
          >
            <Flex 
              justify="space-between" 
              align="center"
              h="60px"
              px={4}
            >
              <IconButton
                icon={<FaBars />}
                onClick={onOpen}
                aria-label="Open menu"
                variant="ghost"
                size="lg"
                _hover={{ bg: 'transparent', transform: 'scale(1.1)' }}
                transition="all 0.2s"
              />
              <Text 
                fontSize="lg" 
                fontWeight="bold"
                color={textColor}
                bgGradient="linear(to-r, blue.500, blue.300)"
                bgClip="text"
              >
                {activeTab}
              </Text>
              <Box w="40px" />
            </Flex>
          </Box>
        )}
        
        <Flex 
          gap={0} 
          flexDirection={isMobile ? 'column' : 'row'}
          position="relative"
        >
          {!isMobile && (
            <Box 
              w="280px" 
              flexShrink={0} 
              bg={bgColor} 
              p={4} 
              borderRadius="2xl"
              boxShadow="xl"
              borderWidth={1}
              borderColor={borderColor}
              position="sticky"
              top="24px"
              h="fit-content"
              overflow="hidden"
              transition="all 0.2s"
              _hover={{ transform: 'translateY(-2px)', boxShadow: '2xl' }}
            >
              {menuItems}
            </Box>
          )}

          <Box 
            as={motion.div}
            {...pageTransition}
            flex={1} 
            minW={0} 
            bg={bgColor} 
            p={isMobile ? 4 : 6}
            borderRadius={isMobile ? 0 : "2xl"}
            boxShadow={isMobile ? "none" : "xl"}
            borderWidth={isMobile ? 0 : 1}
            borderColor={borderColor}
            ml={isMobile ? 0 : 4}
          >
            {!isMobile && (
              <Text 
                fontSize="2xl" 
                fontWeight="bold" 
                mb={6}
                color={textColor}
                bgGradient="linear(to-r, blue.500, blue.300)"
                bgClip="text"
              >
                {activeTab}
              </Text>
            )}
            {renderContent()}
          </Box>
        </Flex>

        <Drawer 
          isOpen={isOpen} 
          placement="left" 
          onClose={onClose}
          size={isMobile ? "full" : "xs"}
        >
          <DrawerOverlay backdropFilter="blur(8px)" />
          <DrawerContent bg={bgColor}>
            <DrawerHeader 
              borderBottomWidth={1}
              py={4}
              boxShadow="sm"
            >
              <Flex justify="space-between" align="center">
                <Text 
                  fontSize="lg" 
                  fontWeight="bold"
                  bgGradient="linear(to-r, blue.500, blue.300)"
                  bgClip="text"
                >
                  用户中心
                </Text>
                <DrawerCloseButton 
                  position="static" 
                  size="lg"
                  borderRadius="full"
                  _hover={{ bg: 'transparent', transform: 'scale(1.1)' }}
                  transition="all 0.2s"
                />
              </Flex>
            </DrawerHeader>
            <DrawerBody py={3}>
              {menuItems}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Container>
    </Box>
  );
};

export default UserCenter;
